header {
  .nav-box {
    position: fixed;
    top: $header-nav-box-top;
    left: $header-nav-box-top;
    z-index: 2;

    svg {
      width: 52px;
      height: 52px;
    }
    .osi-panel {
      position: absolute;
      z-index: -1;
      font-size: 32px;
      left: 0%;
      height: 100%;
      display: flex;
      align-items: center;
      width: 192px;
      padding: 0px 16px 0 24px;
      margin-left: 0px;
      opacity: 0;
      transition: opacity 0.3s, left 0.5s, margin-left 0.5s;
      -webkit-transition: opacity 0.3s, left 0.5s, margin-left 0.5s;
      -moz-transition: opacity 0.3s, left 0.5s, margin-left 0.5s;
      -ms-transition: opacity 0.3s, left 0.5s, margin-left 0.5s;
      -o-transition: opacity 0.3s, left 0.5s, margin-left 0.5s;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }
    &:hover {
      .osi-panel {
        opacity: 1;
        margin-left: -16px;
        left: 100%;
      }
    }
  }
}
