.div-combinations{
    cursor:pointer;
    border:1px solid white;
    border-radius:10px;
    min-height:120px;
}

div .active-comb-back{
    background-color: #71d875;

}

div .div-combinations:hover{
    background-color: #71d875;

}

.form-switch .form-check-input:checked{
    background-color: #71d875;

}
.country-label {
    width: calc(33.333% - 1rem); /* 3'erli grup yapmak için genişliği ayarla */
    text-align: center;
  }
  
  
.together-product .accordion__body .accordion__body__content{
    overflow-y: auto;
    max-height: 500px;
}

.together-product .text-truncate{
    max-width: 100%;
}

.text-align-table{
    text-align: left;
}

.bg-users{
    background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0% 0% no-repeat padding-box;
    border-radius: var(--bs-border-radius);
}
.order .form-check-input{
    width: 1.3rem;
    height: 1.3rem;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
  }
  
  .modal-content button {
    margin: 5px;
  }
  