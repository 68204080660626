.drag-drop__list {
  display: grid;
  gap: 16px 80px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding-bottom: 15px;
}

.drag-drop input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0;
}

.drag-drop .drag-drop__container {
  min-height: 64px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position: relative;
  padding: 20px;
  border: 2px dashed rgb(82, 82, 82);
}

.drag-drop .drag-drop__container:hover {
  border-color: #7f63f4;
  cursor: pointer;
}

.drag-drop .drag-drop__btn {
  width: 100%;
}
.drag-drop .drag-drop__btn svg {
  background-color: var(--surface-1);
  font-size: 48px;
  margin: 0 auto;
}

.drag-drop .drag-drop__size,
.drag-drop .drag-drop__name {
  color: var(--gray-1);
}

.drag-drop .drag-drop__errors li {
  color: #ff5274;
}

.drag-drop .file-bar__wrapper {
  display: flex;
  align-items: center;
  width: 200px;
  max-width: 100%;
  justify-content: space-between;
  gap: 10px;
}
.file-bar__wrapper > div {
  text-overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drag-drop .drag-drop__errors li + li {
  margin-top: 0.3rem;
}
