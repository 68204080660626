.tabs .tabs__nav {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.tabs .tabs__nav::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  content: "";
  height: 2px;
  background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0% 0%
    no-repeat padding-box;
  z-index: -1;
}

.tabs .tabs__ink {
  background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0% 0%
    no-repeat padding-box;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100px;
  z-index: -2;
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -o-border-radius: 10px 10px 0 0;
  transition: left 0.4s, width 0.4s;
  -webkit-transition: left 0.4s, width 0.4s;
  -moz-transition: left 0.4s, width 0.4s;
  -ms-transition: left 0.4s, width 0.4s;
  -o-transition: left 0.4s, width 0.4s;
}

.tabs .tabs__nav .tabs__nav-item {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -ms-border-radius: 10px 10px 0 0;
  -o-border-radius: 10px 10px 0 0;
  color: var(--bs-body-color);
  transition: color 0.4s;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -ms-transition: color 0.4s;
  -o-transition: color 0.4s;
}
.tabs .tabs__nav .tabs__nav-item.active {
  color: white;
}

.tabs .tabs__nav-item-container {
  position: relative;
  max-width: calc(100% - 104px);
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-wrap: nowrap;
}

.tabs .tabs__nav-item-container::-webkit-scrollbar {
  display: none;
}
.tabs .tabs__nav .prev,
.tabs .tabs__nav .next {
  width: 42px;
  font-size: 18px;
  color: inherit;
}

.tabs .tabs__nav .prev {
  margin-right: 5px;
}
.tabs .tabs__nav .next {
  margin-left: 5px;
}

.tabs .tabs__pane-container {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.tabs .tabs__pane {
  display: none;
  width: 100%;
  padding: 10px;
}

.tabs .tabs__pane.active {
  display: block;
}
