aside {
  z-index: 5;
  position: fixed;
  width: $aside-width;
  height: $aside-width;
  max-height: calc(100% - $header-nav-box-top * 2);
  top: $header-nav-box-top;
  left: $header-nav-box-top;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 12px;
  overflow: hidden;

  -ms-overflow-style: none;
  scrollbar-width: none;

  nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 27px;
    margin-bottom: 10px;
    height: calc(100% + $header-nav-box-top - $aside-width * 2);
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .accordion {
    min-width: 100%;
    &__header {
      padding-left: 0;
    }
    &__body {
      padding: 0px 0px;
    }

    &.active {
      & > .accordion__body {
        padding: 12px 0px;
      }
    }

    .aside__icon {
      font-size: 30px;
      min-width: 30px;
      margin: 0 9px;
    }
    a + a {
      display: block;
      margin-top: 10px;
    }
  }

  &.active {
    width: 300px;
    backdrop-filter: blur(6px);
    height: 100%;
    max-height: calc(100% - $header-nav-box-top * 2);

    .accordion {
      &__header {
        padding-left: 0;
      }
      &__body {
        padding: 0px 12px;
      }

      &.active {
        & > .accordion__body {
          padding: 12px 12px;
        }
      }
    }
    .nav-item:not(.menu) {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      z-index: 1;
    }
    a:not(.active) .nav-item:not(.menu)::before {
      content: "";
      width: 0%;
      position: absolute;
      height: 100%;
      z-index: -1;

      left: 0;
      top: 0;
      background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%)
        0% 0% no-repeat padding-box;
    }

    a:not(.active) .nav-item:not(.menu):hover::before {
      width: 100%;
    }
  }
  transition: width 0.4s, height 0.4s;
  -webkit-transition: width 0.4s, height 0.4s;
  -moz-transition: width 0.4s, height 0.4s;
  -ms-transition: width 0.4s, height 0.4s;
  -o-transition: width 0.4s, height 0.4s;

  .logout .nav-item {
    white-space: nowrap;
  }
}

@media (hover: hover) {
  aside.acitve {
    a:not(.active) .nav-item:not(.menu)::before {
      transition: width 0.5s;
      -webkit-transition: width 0.5s;
      -moz-transition: width 0.5s;
      -ms-transition: width 0.5s;
      -o-transition: width 0.5s;
    }
  }
}
@media (min-width: 1200px) {
  aside {
    top: calc($aside-width + $header-nav-box-top * 2);
    height: 100%;
    max-height: calc(100% - $aside-width - $header-nav-box-top * 3);
    &.active {
      max-height: calc(100% - $aside-width - $header-nav-box-top * 3);
    }
  }
}
