.login {
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-card {
    width: 400px;
    max-width: calc(100% - 15px) !important;
    .company-logo {
      width: 50px;
    }
  }
}
