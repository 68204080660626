.quill.form-control {
  padding: 0;
}
.quill .ql-toolbar.ql-snow {
  background-color: #fefefe !important;
}
.ql-toolbar.ql-snow {
  padding: 10px 5px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid;
  font-family: "montserrat";
}
.ql-container.ql-snow {
  border: 0;
  font-family: "montserrat";
}

.ql-editor {
  min-height: 150px;
  font-size: 16px;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-bg-state: transparent;
}

.modal-dialog {
  margin-top: $header-nav-box-top;
}

/* MULTI SELECT */

.multiselect-container .searchWrapper {
  border: none;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  padding-bottom: 0 !important;
  padding: 0 !important;
}

.form-group.not-bordered .multiselect-container .option {
  padding: 15px;
}
.form-group.not-bordered .multiselect-container .searchWrapper {
  border-bottom: none;
  margin-bottom: 0 !important;
}

.multiselect-container .searchBox {
  margin-bottom: 0 !important;
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 7.5px 10px;
}
.multiSelectContainer input {
  margin-top: 0 !important;
}
.form-group.not-bordered .multiselect-container .chip {
  background-color: transparent !important;
  padding: 0;
}

.multiselect-container .chip {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  justify-content: space-between;
}

.multiselect-container .chip {
  color: $light;
  min-height: 32px;
  gap: 5px;
}

.multiselect-container .chip + input {
  margin-top: 5px !important;
}

.multiselect-container .searchWrapper {
  text-wrap: wrap;
}

.multiselect-container .optionListContainer {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
}
.multiselect-container .icon_down_dir {
  display: none;
}
.multiselect-container .optionContainer {
  border: none;
}

.multiselect-container .optionListContainer {
  display: grid;
  transition: grid-template-rows 0.3s;
  -webkit-transition: grid-template-rows 0.3s;
  -moz-transition: grid-template-rows 0.3s;
  -ms-transition: grid-template-rows 0.3s;
  -o-transition: grid-template-rows 0.3s;
}

.multiselect-container .optionContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.multiselect-container .optionContainer::-webkit-scrollbar {
  display: none;
}

.multiselect-container .optionListContainer.displayNone {
  grid-template-rows: 0fr;
}

.multiselect-container .optionListContainer:not(.displayNone) {
  grid-template-rows: 1fr;
}

.slot {
  &-char {
    font-size: 32px;
    font-weight: 300;
  }
}
