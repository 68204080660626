.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.card-title {
  font-weight: bold;
}

.stretch {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s;
  -webkit-transition: grid-template-rows 0.3s;
  -moz-transition: grid-template-rows 0.3s;
  -ms-transition: grid-template-rows 0.3s;
  -o-transition: grid-template-rows 0.3s;
}

.stretch .stretch__content {
  overflow: hidden;
}
.stretch.active {
  grid-template-rows: 1fr;
  // margin-bottom: 10px;
}

.price {
  color: $green;
}

.pill {
  display: inline-block;
  min-width: 70px;
  padding: 3px;
  border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
  font-weight: 700;
}

.pill.danger {
  color: #b72236;
  background-color: #ffe5e4;
}
.pill.success {
  background-color: rgb(223, 242, 219);
  color: #30a021;
}
