body[data-bs-theme="dark"] {
  background-color: $darker;
}
[data-bs-theme="dark"] {
  --bs-body-bg: #{$darker} !important;
  --bs-body-color: #{$light} !important;

  .multiselect-container .searchBox {
    border: 0.5px solid $muted;
    background-color: $darker;
  }

  .multiSelectContainer input::placeholder {
    color: $light;
  }

  .multiselect-container .chip {
    background: $secondary !important;
    color: $light;
    gap: 5px;
  }

  .multiselect-container .optionListContainer {
    background-color: $dark;
  }

  .multiselect-container .option:hover,
  .multiselect-container .highlight,
  .multiselect-container .highlightOption {
    background-color: $darker;
  }
  .multiselect-container .option {
    color: $light;
  }

  .loader {
    background: $darker;
  }
  .company-logo {
    fill: $white;
  }
  aside {
    background-color: $black;
    &.active {
      background: rgba(0, 0, 0, 0.75);
    }
  }
  .popover {
    --bs-popover-bg: #{$secondary} !important;
  }
  .card {
    --bs-body-bg: $dark;
    --bs-border-color-translucent: $darker;
    background-color: $dark;
  }

  .form-control {
    --bs-border-color: #{$muted};
    &::placeholder {
      opacity: 1;
      color: #{$light};
    }
  }
  .custom-table-bordered-head.user-roles {
    background-color: $darker;
  }
  .custom-table-bordered-head {
    --bs-table-border-color: #{$lighter};
    --bs-table-color-state: #{$lighter};
    :not(thead)tr:hover {
      background-color: $secondary;
    }
  }
  .custom-table-bordered-head thead th:nth-child(9),
  .custom-table-bordered-head thead th:nth-child(8),
  .custom-table-bordered-head thead th:nth-child(7),
  .custom-table-bordered-head thead th:nth-child(6),
  .custom-table-bordered-head thead th:nth-child(5),
  .custom-table-bordered-head thead th:nth-child(4),
  .custom-table-bordered-head thead th:nth-child(3),
  .custom-table-bordered-head thead th:nth-child(2) {
    border-bottom: 1px solid $light;
    border-top: 1px solid $light;
  }

  .custom-table-bordered-head thead th:first-child,
  .custom-table-bordered-head thead th:last-child {
    border-color: $light;
  }
  .input-group.outlined {
    --bs-border-color: #{$light};
    --bs-body-color: #{$light};
    --bs-tertiary-bg: #{$dark};
    .form-control {
      --bs-border-color: #{$light};
      --bs-body-color: #{$light};
    }
  }

  header {
    .nav-box {
      background-color: $black;
      .osi-panel {
        background-color: $black;
      }
    }
  }
  .menu-line {
    background-color: $white;
  }
  .drawer {
    background: $black;
  }

  .pop-up {
    &-item:hover {
      cursor: pointer;
      background-color: $dark;

      svg,
      path {
        fill: $blue;
      }
    }
  }
  .mail-box {
    background-color: $secondary !important;

    &__drawer {
      background-color: $dark;
      ul {
        li {
          button {
            border-left: 2px solid #272727;
            .side-bar__count {
              background-color: var(--bs-body-color);
              color: $darker;
            }
          }
        }
        li.active {
          button {
            color: $purple;
            border-left-color: var(--bs-body-color);
          }
        }
      }
    }

    &__main {
      &__messages {
        input {
          border-bottom: 1px solid $gray-half;
          &::placeholder {
            color: $gray-half;
          }
        }
      }
    }
  }

  .mail-card__message {
    border: 1px solid var(--bs-body-color);
  }
  .mail-card {
    border: 1px solid var(--bs-body-color);

    &__message.active {
      border-color: $purple;
      background-color: $darker;
    }
    &.active {
      border-color: $purple;
      background-color: $darker;
    }
    &__unread {
      background-color: $purple;
    }
  }

  .mail-message {
    &__attachments {
      border-top: 1px solid $gray-half;
      border-bottom: 1px solid $gray-half;
    }
  }

  .mail-compose {
    .form-control {
      border-color: var(--bs-body-color);
      background-color: transparent;
    }
    input {
      border-color: var(--bs-body-color);
      background-color: transparent;
    }
    .drag-drop-container {
      border-color: var(--bs-body-color);
    }
    .ql-toolbar {
      background-color: var(--bs-body-color);
    }
  }
  @media (hover: hover) and (pointer: fine) {
    .mail-card {
      &:hover {
        background-color: $dark;
      }
    }
  }
}
