.accordion {
  min-width: 276px;
  color: white;
}

.accordion__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  width: 100%;
  gap: 10px;
  border: none;
}
.accordion__header .accordion__header__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
}
.accordion .accordion__icon {
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
}
.accordion__body {
  margin-top: 0px;
  padding: 0px 12px;
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s, padding 0.3s, margin-top 0.3s;
  -webkit-transition: grid-template-rows 0.3s, padding 0.3s, margin-top 0.3s;
  -moz-transition: grid-template-rows 0.3s, padding 0.3s, margin-top 0.3s;
  -ms-transition: grid-template-rows 0.3s, padding 0.3s, margin-top 0.3s;
  -o-transition: grid-template-rows 0.3s, padding 0.3s, margin-top 0.3s;
}

.accordion__header,
.accordion__body {
  background-color: hsl(222, 8%, 35%);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.accordion__body .accordion__body__content {
  overflow: hidden;
}

/* ACTIVE */
.accordion.active > .accordion__header .accordion__icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.accordion.active > .accordion__body {
  margin-top: 10px;
  grid-template-rows: 1fr;
  padding: 12px 12px;
}
.accordion .accordion .accordion__body,
.accordion .accordion .accordion__header {
  background-color: #272727;
}
.accordion .accordion .accordion__body {
  margin-top: 5px;
}
.accordion .accordion {
  margin: 10px 0px;
}
.accordion .form-label {
  margin-bottom: 0;
}

/* .accordion + .accordion {
  margin-bottom: 10px;
}
 */
