.mail-box {
  padding: 15px !important;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  display: flex;
  gap: 15px;

  &__filters {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    margin-top: 5px;
    gap: 5px;
    button {
      opacity: 0.5;
      font-size: 13px;
      &.active {
        opacity: 1;
      }
    }
  }

  &__drawer {
    padding: 20px 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: column;
      gap: 15px;
      flex-grow: 1;
      overflow: auto;
      li {
        list-style: none;
        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          padding: 7.5px 15px;
          width: 225px;
          font-size: 14px;
          border-left: 2px solid #272727;
          .side-bar__text {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .side-bar__count {
            font-weight: 300;
            width: 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
          }
        }
      }
    }
    .compose {
      padding: 0 15px;
      &-button {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 5px;
        svg {
          font-size: 24px;
          min-width: 24px;
        }
      }
    }
  }

  &__main {
    display: flex;
    width: 100%;
    .mail-message__container {
      padding: 15px;
      width: 100%;
    }
    &__messages {
      input {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border: none;
        border-bottom: 1px solid $gray-half;
        padding: 0;
        font-size: 14px;
        width: 100%;
      }
    }
  }
}

.mail-card__message {
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  font-size: 14px;
  font-weight: 300;
  min-height: 100px;
  margin-right: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
  + {
    .mail-card__message {
      margin-top: 10px;
    }
  }
  .mail-card__message_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.mail-card {
  border-radius: 5px;
  padding: 10px 10px 10px 15px;
  font-size: 14px;
  font-weight: 300;
  min-height: 100px;
  margin-right: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;

  &__message__header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    opacity: 0.5;
  }

  + {
    .mail-card {
      margin-top: 10px;
    }
  }
  &__unread {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  &__body {
    display: flex;
    opacity: 0.5;
    gap: 5px;
    flex-wrap: wrap;
  }
  &__from {
    text-overflow: ellipsis;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
  }
}

.mail-message {
  &:hover {
    cursor: pointer;
  }
  &__header {
    margin-top: 15px;
    margin-bottom: 30px;
  }
  &__category {
    margin-bottom: 30px;
    font-size: 14px;
    opacity: 0.5;
  }
  &__attachments {
    margin-bottom: 30px;
    border-top: 1px solid $gray-half;
    border-bottom: 1px solid $gray-half;
    padding: 30px 10px;
    a {
      margin-top: 10px;
      opacity: 0.4;
    }
    svg {
      font-size: 36px;
    }
  }
  &__body {
    margin-bottom: 30px;
  }
  &__title {
    font-size: 22px;
    font-weight: 300;
  }
  &__from {
    font-size: 28px;
    font-weight: 500;
  }
  &__date {
    font-size: 14px;
    opacity: 0.5;
  }
  &__from-mail {
    font-size: 14px;
    opacity: 0.5;
  }
  button {
    font-weight: 300;
  }
}

.mail-compose {
  .form-control {
    background-color: transparent;
  }
  input {
    background-color: transparent;
  }
}

@media (max-width: 992px) {
  .mail-box__main {
    flex-direction: column;
    width: 100%;
    .mail-message__container {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .mail-box__drawer {
    .compose {
      .compose-button {
        div {
          display: none;
        }
      }
    }
    ul {
      li {
        button {
          .side-bar__text {
            div {
              display: none;
            }
          }
          width: 85px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .mail-box__main {
    .mail-box__messages {
      border-right: 1px solid $gray-half;
      padding: 15px 15px 15px 0;
      min-width: 300px;
      max-width: 100%;
    }
  }
  .mail-message__container {
    height: calc(100vh - 150px);
    overflow: auto;
  }
  .mail-box__messages__container {
    height: calc(100vh - 313px);
    overflow: auto;
  }
}
@media (max-width: 768px) {
  .mail-box {
    flex-direction: column;
  }
  .mail-box__main {
    .mail-box__messages {
      padding-bottom: 15px;
      border-bottom: 1px solid $gray-half;
    }
    padding: 5px;
  }
  .mail-box__drawer {
    ul {
      flex-direction: row;
      gap: 5px;
      flex-wrap: wrap;
    }
  }
}
