.drag-drop-container {
  border: 2px dashed $muted;
  position: relative;
  padding: 20px;
  text-wrap: balance;
  &:hover {
    cursor: pointer;
    border-color: $purple;
  }
  .input-field {
    cursor: pointer;
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
  }
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
