@import "dark-theme";
@import "light-theme";
:root {
  --toastify-color-error: #{$danger};
}
body {
  --bs-form-invalid-color: #{$danger};
  --bs-form-invalid-border-color: #{$danger};
  input[type="checkbox"].blue {
    border-color: $light;
  }
  .link-hover:hover {
    color: $blue !important;
  }
  .form-check-input:focus.blue {
    box-shadow: 0 0 0.25rem $light;
  }
  .form-check-input:checked.blue {
    box-shadow: 0 0 0.25rem $blue;
    background-color: $blue;
    border-color: $blue;
  }

  .form-check-input:focus.success {
    box-shadow: 0 0 0.25rem $success;
  }
  .form-check-input:checked.success {
    box-shadow: 0 0 0.25rem $success;
    background-color: $success;
    border-color: $success;
  }
}
