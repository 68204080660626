.theme-switcher {
  width: 63px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  cursor: pointer;
  background-color: $white;

  svg {
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
  }

  .sun {
    opacity: 0;
  }
  .moon {
    opacity: 1;
  }
  .rounded-circle {
    background-color: $black;
    left: 7.5px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    width: 20px;
    height: 20px;
    transform: translate(0%, 0);
    -webkit-transform: translate(0%, 0);
    -moz-transform: translate(0%, 0);
    -ms-transform: translate(0%, 0);
    -o-transform: translate(0%, 0);
  }

  &.active {
    background-color: $success;
    .sun {
      opacity: 1;
    }
    .moon {
      opacity: 0;
    }
    .rounded-circle {
      background-color: $white;
      left: calc(100% - 7.5px);
      transform: translate(-100%, 0);
      -webkit-transform: translate(-100%, 0);
      -moz-transform: translate(-100%, 0);
      -ms-transform: translate(-100%, 0);
      -o-transform: translate(-100%, 0);
    }
  }
}
