$black: #000;
$primary: #9ab3d5;
$secondary: hsl(222, 8%, 35%);
$darker: #1d1d1d;
$dark: #272727;
$dark-half: rgb(39, 39, 39, 0.5);
$danger: #ff5274;
$purple: #7f63f4;
$success: #71d875;
$warning: #f3ff07;
$muted: rgb(82, 82, 82);
$gray-half: rgb(239, 239, 239, 0.5);
$lighter: #c5c5c5;
$light: #efefef;
$white: #fff;
$darkish: #2e2e30;

@import "node_modules/bootstrap/scss/_functions";
@import "node_modules/bootstrap/scss/_variables";

$custom-theme-colors: (
  "darker": $darker,
  "lighter": $lighter,
  "purple": $purple,
  "muted": $muted,
  "blue": $blue,
);

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

$theme-colors: map-merge($custom-theme-colors, $theme-colors);
