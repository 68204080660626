.menu {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;

  &-line {
    width: 30px;
    height: 4px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
  &.active &-line,
  &:hover &-line {
    background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0%
      0% no-repeat padding-box;
  }
}

.nav-item {
  border-radius: 6px;
  height: 48px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: color 0.4s;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -ms-transition: color 0.4s;
  -o-transition: color 0.4s;
  z-index: 1;
  position: relative;

  &-img {
    min-width: 30px;
    height: 30px;
    margin-left: 9px;
    margin-right: 9px;
  }
  &-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 175px;
    overflow: hidden;
  }

  &.menu {
    cursor: pointer;
  }
  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0%
      0% no-repeat padding-box;
    transition: width 0.4s;
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    -ms-transition: width 0.4s;
    -o-transition: width 0.4s;
  }
  &:hover {
    color: $light;
  }
}

a.active .nav-item {
  color: $light;

  &:hover::before {
    width: 0%;
  }
}

@media (hover: hover) and (pointer: fine) {
  .nav-item:hover::before,
  .menu .nav-item:hover::before {
    width: 100%;
  }
}
