@import "typography";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "montserrat";
  scroll-behavior: smooth;
}

body {
  scrollbar-gutter: stable;
}

main {
  scrollbar-gutter: stable;
  margin-top: $margin-top;
}

.h1 {
  font-size: 2.65rem;
  margin-bottom: 25px;
}
.h2 {
  margin-bottom: 20px;
}
.h5 {
  margin-bottom: 15px;
}

section {
  scroll-margin-top: calc($header-nav-box-top * 3 + $aside-width);
  margin-bottom: 25px;
}
table {
  border-collapse: separate;
  border-spacing: 0px;
}

table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-table-bordered-head thead th:first-child {
  border-radius: 6px 0 0 6px;
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  -ms-border-radius: 6px 0 0 6px;
  -o-border-radius: 6px 0 0 6px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.custom-table-bordered-head thead th:last-child {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: none;
  border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0 6px 6px 0;
  -moz-border-radius: 0 6px 6px 0;
  -ms-border-radius: 0 6px 6px 0;
  -o-border-radius: 0 6px 6px 0;
}

table tbody tr td {
  max-width: 175px;
}
table tbody tr td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
table tbody tr td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-table-bordered-head {
  --bs-table-border-color: #{$black};
  --bs-table-color-state: #{$black};
}
.custom-table-bordered-head td {
  font-size: 0.90rem;
}

.custom-table-bordered-head th {
  font-size: 0.90rem;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}
a.active .nav-item {
  background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0% 0%
    no-repeat padding-box;
}

small {
  font-size: 0.875em;
}

.arrow.popover-arrow {
  display: none;
}

ul {
  padding: 0px !important;
}

li {
  // list-style: none;
  margin-bottom: 0 !important;
}

.form-control:focus {
  box-shadow: none;
}
.form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

hr {
  margin: 0px;
}

.btn:not(.btn-link) {
  display: flex;
  align-items: center;
}
.btn:not(.btn-link) svg {
  font-size: 20px;
}
.btn-link {
  text-decoration: none;
  color: $light;
}
.text-truncate {
  max-width: 250px;
}

.input-group {
  width: auto;
}
.input-group.outlined .form-control {
  border-right: none;
}

.input-group.outlined .input-group-text {
  border-left: none;
}
.placeholder {
  max-width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

::-webkit-scrollbar {
  height: 6.5px;
  width: 6.5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /*  background: transparent linear-gradient(45deg, #40826d 0%, #7fda69 100%) 0% 0%
    no-repeat padding-box; */
  background-color: $lighter;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media (min-width: 992px) {
  section {
    scroll-margin-top: calc($header-nav-box-top * 2 + $aside-width);
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: calc(100% - $header-nav-box-top * 2);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: calc(100% - $aside-width * 2 - $header-nav-box-top * 7);
  }
  main {
    margin-top: calc($aside-width / 2 - $header-nav-box-top * 2);
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1220px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1320px;
  }
}
