body[data-bs-theme="light"] {
  background-color: $light;
}
[data-bs-theme="light"] {
  background-color: $light;
  --bs-body-color: #{$black} !important;

  .text-warning {
    color: rgb(255, 119, 0) !important;
  }
  .loader {
    background: $light;
  }
  .card {
    --bs-body-bg: $white;
    --bs-border-color-translucent: $light;
    background-color: $white;
  }
  .company-logo {
    fill: #262626;
  }
  aside {
    background-color: $lighter;
    &.active {
      background-color: rgba(197, 197, 197, 0.75);
    }
  }
  .lds-spinner {
    &.dark {
      div {
        &:after {
          background: transparent
            linear-gradient(45deg, $black 0%, $darker 100%) 0% 0% no-repeat
            padding-box;
        }
      }
    }
  }
  header {
    .nav-box {
      background-color: $lighter;
      .osi-panel {
        background-color: $lighter;
      }
    }
  }
  .menu-line {
    background-color: $black;
  }
  .custom-table-bordered-head.user-roles {
    background-color: $white;
  }
  .custom-table-bordered-head {
    --bs-table-border-color: #{$black};
    --bs-table-color-state: #{$black};

    :not(thead)tr:hover {
      background-color: $lighter;
    }
  }
  .custom-table-bordered-head thead th:nth-child(9),
  .custom-table-bordered-head thead th:nth-child(8),
  .custom-table-bordered-head thead th:nth-child(7),
  .custom-table-bordered-head thead th:nth-child(6),
  .custom-table-bordered-head thead th:nth-child(5),
  .custom-table-bordered-head thead th:nth-child(4),
  .custom-table-bordered-head thead th:nth-child(3),
  .custom-table-bordered-head thead th:nth-child(2) {
    border-bottom: 1px solid $black;
    border-top: 1px solid $black;
  }
  .drawer {
    background-color: rgb(197, 197, 197);
  }
  .pop-up {
    &-item:hover {
      background-color: $light;
    }
  }

  .profile {
    &-container svg #profile_bg {
      fill: $black;
    }
    #user {
      fill: $light;
    }
    &.active &-container svg #user,
    &-container:hover svg #user {
      fill: $light;
    }

    &.active &-container svg #profile_bg,
    &-container:hover svg #profile_bg {
      fill: transparent;
    }
  }
  .login {
    background-color: $light !important;
  }

  .mail-box {
    background-color: rgba(197, 197, 197, 0.9) !important;

    &__drawer {
      background-color: $light;
      ul {
        li {
          button {
            border-color: $light;
            color: $black;
            background-color: $light;
            .side-bar__count {
              background-color: var(--bs-body-color);
              color: $light;
            }
          }
        }
        li.active {
          button {
            color: $purple;
            border-left-color: var(--bs-body-color);
          }
        }
      }
    }

    &__main {
      &__messages {
        input {
          border-bottom: 1px solid $dark-half;
          &::placeholder {
            color: $dark-half;
          }
        }
      }
    }
  }

  .mail-card__message {
    border: 1px solid var(--bs-body-color);
  }
  .mail-card {
    border: 1px solid var(--bs-body-color);

    &__message.active {
      border-color: $purple;
      background-color: $white;
    }
    &.active {
      border-color: $purple;
      background-color: $white;
    }
    &__unread {
      background-color: $purple;
    }
  }

  .mail-message {
    &__attachments {
      border-top: 1px solid $dark-half;
      border-bottom: 1px solid $dark-half;
    }
  }

  .mail-compose {
    .form-control {
      border-color: var(--bs-body-color);
      background-color: transparent;
    }
    input {
      border-color: var(--bs-body-color);
      background-color: transparent;
    }
    .drag-drop-container {
      border-color: var(--bs-body-color);
    }
    .ql-toolbar {
      background-color: transparent;
    }
  }

  /* hey */

  .multiselect-container .searchBox {
    border: 0.5px solid $light;
    background-color: $white;
  }

  .multiSelectContainer input::placeholder {
    color: $muted;
  }

  .multiselect-container .chip {
    background: $secondary !important;
    color: $light;
  }

  .multiselect-container .optionListContainer {
    background-color: $white;
  }

  .multiselect-container .option:hover,
  .multiselect-container .highlight,
  .multiselect-container .highlightOption {
    background-color: $light;
  }
  .multiselect-container .option {
    color: var(--bs-body-color);
  }

  /* HEY */

  @media (min-width: 992px) {
    .mail-box__main {
      .mail-box__messages {
        border-right: 1px solid $dark-half;
      }
    }
  }
  @media (hover: hover) and (pointer: fine) {
    .mail-card {
      &:hover {
        background-color: $light;
      }
    }
  }
}
