.drawer {
  position: fixed;
  z-index: 5;
  top: $header-nav-box-top;
  right: -350px;
  height: calc(100% - $header-nav-box-top * 2);
  overflow-y: auto;
  width: 350px;
  max-width: calc(100% - $header-nav-box-top * 2);
  transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 12px;
  scrollbar-gutter: stable;
  &.lg {
    right: calc(-1 * 160% - 102px);
    width: calc(100% - 102px);
  }
  &.open {
    right: $header-nav-box-top;
  }
}

@media (max-width: 1199.98px) {
  .drawer {
    &.lg {
      right: -100%;
      width: 100%;
    }
    &.open {
      right: $header-nav-box-top;
    }
  }
}
